import {
  Box,
  Button,
  Container,
  createMuiTheme,
  Hidden,
  Paper,
  ThemeOptions,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { FlexCol, FlexGrowCol, FlexGrowRow, FlexRow } from 'sdk-xy-react'

import ps5 from '../img/ps5.jpg'
import sentinelx from '../img/sentinelx.jpg'
import teslaTequila from '../img/tesla-tequila.jpg'
import theragun from '../img/theragun.png'
import xbox from '../img/xbox.png'

const HeaderFontFamily = 'Big Shoulders Stencil Text'

const fontFamilyPrimary = ['Poppins', 'Helvetica', 'sans-serif'].join(',')

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: fontFamilyPrimary,
  },
}

const theme = createMuiTheme(themeOptions)

const MainToolbarTitle: React.FC = () => {
  return (
    <Box fontFamily={HeaderFontFamily} fontSize="48px">
      Hot Tech Apps
    </Box>
  )
}

const MainToolbarNav: React.FC = () => {
  return (
    <Box>
      <Button>Gifts</Button>
      <Button>Gadgets</Button>
      <Button>Apps</Button>
    </Box>
  )
}

const MainToolbar: React.FC = () => {
  return (
    <FlexRow component={Toolbar}>
      <Hidden smDown>
        <FlexGrowRow justifyContent="flex-start">
          <MainToolbarTitle />
          <Box flexGrow={1} />
          <MainToolbarNav />
        </FlexGrowRow>
      </Hidden>
      <Hidden mdUp>
        <FlexCol alignItems="center" justifyContent="flex-start">
          <MainToolbarTitle />
          <Box flexGrow={1} />
          <MainToolbarNav />
        </FlexCol>
      </Hidden>
    </FlexRow>
  )
}

const CoinSentinelX: React.FC = () => {
  return (
    <ProductItem
      buttonText="Order at COIN"
      description="This is our top new entry for 2020. Besides being a very innovative and refreshing gadget, is is priced just
      right as a stocking stuffer for all of your hard to shop for friends and family."
      img={sentinelx}
      link="https://coinapp.co/holiday/sentinelx/nfc"
      title="#2. SentinelX by COIN - $20/each"
      winner="Best Deal"
    />
  )
}

const PlayStation: React.FC = () => {
  return (
    <ProductItem
      buttonText="Order at Amazon"
      description="The iconic Playstation is back again with its fifth version. Early reviews suggest that Sony does not
      disappoint, and this is the must have gift for the season."
      img={ps5}
      link="https://amzn.to/3nIQ8dk"
      title="#1. Playstation 5 by Sony - $499"
      winner="Hardest to Find"
    />
  )
}

const XBox: React.FC = () => {
  return (
    <ProductItem
      buttonText="Order at Amazon"
      description="Just like Sony, Microsoft launched their most recent entry in the game console battle just in time for the
      holidays. Use the buttons below to check for availability."
      img={xbox}
      link="https://amzn.to/35W0yQO"
      title="#3. Xbox Series X by Microsoft - $499"
      winner="Alternative to PS5"
    />
  )
}

const Theragun: React.FC = () => {
  return (
    <ProductItem
      buttonText="Order at Amazon"
      description="Everyone has at least one friend or relative that always is asking for their sholders to be massaged. Now you
      can get out of that job with the best of breed Theragun."
      img={theragun}
      link="https://amzn.to/35YirhO"
      title="#4. Theragun Elite by Theragun - $299"
      winner="Most Practical"
    />
  )
}

interface ProductItemProps {
  link: string
  img: string
  title: string
  description: string
  buttonText: string
  winner: string
}

const ProductItem: React.FC<ProductItemProps> = (props) => {
  const { winner, link, img, title, description, buttonText } = props
  return (
    <FlexRow onClick={() => window.open(link, '_blank')}>
      <Hidden smDown>
        <FlexRow border={1} borderColor="black" component={Paper} margin={1}>
          <FlexRow
            height={300}
            style={{
              backgroundImage: `url(${img})`,
            }}
            width={300}
          />
        </FlexRow>
        <FlexGrowCol alignItems="stretch" justifyContent="flex-start" margin={1} padding={1}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h6">{description}</Typography>
          <FlexGrowRow />
          <Typography variant="h6">{`"${winner}"`}</Typography>
          <FlexGrowRow />
          <FlexRow justifyContent="space-between">
            <Button fullWidth href={link} style={{ backgroundColor: '#febd69' }} target="_blank" variant="contained">
              {buttonText}
            </Button>
          </FlexRow>
        </FlexGrowCol>
      </Hidden>
      <Hidden mdUp>
        <ProductItemMobile {...props} />
      </Hidden>
    </FlexRow>
  )
}

const ProductItemMobile: React.FC<ProductItemProps> = (props) => {
  const { winner, link, img, title, description, buttonText } = props
  return (
    <FlexCol onClick={() => window.open(link, '_blank')}>
      <FlexRow border={1} borderColor="black" component={Paper} margin={1}>
        <FlexRow
          height={300}
          style={{
            backgroundImage: `url(${img})`,
          }}
          width={300}
        />
      </FlexRow>
      <FlexGrowCol alignItems="stretch" justifyContent="flex-start" margin={1} padding={1}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="h6">{description}</Typography>
        <FlexGrowRow marginY={0.5}>
          <Typography variant="h6">{`"${winner}"`}</Typography>
        </FlexGrowRow>
        <FlexRow justifyContent="space-between">
          <Button fullWidth href={link} style={{ backgroundColor: '#febd69' }} target="_blank" variant="contained">
            {buttonText}
          </Button>
        </FlexRow>
      </FlexGrowCol>
    </FlexCol>
  )
}

const Tesla: React.FC = () => {
  return (
    <ProductItem
      buttonText="Order at Tesla"
      description="For the big spenders out there, the Tesla Model 3 is available and VERY popular. Make ANYONE very happy buy
  getting them this big ticket item! But in case you can not afford the car, you can always get the Tequila!"
      img={teslaTequila}
      link="https://shop.tesla.com/product/tesla-tequila"
      title="#5. Tesla Tequila by Tesla - $250"
      winner="That really exists?"
    />
  )
}

const MainPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <FlexGrowCol alignItems="stretch" justifyContent="flex-start" maxWidth="100vw" minHeight="100vh">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hot Tech Apps: Best Holiday Gifts</title>
          <meta content="index, follow" name="robots" />
        </Helmet>
        <MainToolbar />
        <Container>
          <FlexGrowCol alignItems="stretch" justifyContent="flex-start">
            <Typography variant="h3">Top 5 Tech Gifts for Your Special Someone</Typography>
            <FlexRow margin={1}>
              <Typography variant="h6">
                Welcome to our annual list of tech best tech gifts for your special someone. 2020 has been a tough year
                for most of us, so our take this year is focused a little more on staying home and staying safe.
              </Typography>
            </FlexRow>
            <PlayStation />
            <CoinSentinelX />
            <XBox />
            <Theragun />
            <Tesla />
          </FlexGrowCol>
        </Container>
        <FlexRow marginY={2}>
          <Typography variant="caption">Copyright 2020 - HotTechApps.Com - All Rights Reserved</Typography>
        </FlexRow>
        <FlexRow marginY={2}>
          <Typography variant="caption">Advertisement</Typography>
        </FlexRow>
      </FlexGrowCol>
    </ThemeProvider>
  )
}

export default MainPage
