import GitInfo from 'react-git-info/macro'
import Rollbar, { Configuration } from 'rollbar'

import isLocalhost from './isLocalHost'

export class Log {
  static gitInfo = GitInfo()

  static dev = localStorage.getItem('dev') ?? false

  static POST_CLIENT_ITEM_TOKEN = 'd4766b39c1cf47f49e8ea69d6b41d49f'

  static rollbarConfig: Configuration = {
    accessToken: Log.POST_CLIENT_ITEM_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    codeVersion: Log.gitInfo.commit.hash,
    code_version: Log.gitInfo.commit.hash,
    payload: {
      codeVersion: Log.gitInfo.commit.hash,
      code_version: Log.gitInfo.commit.hash,
      environment: isLocalhost ? 'development' : 'production',
    },
    sendConfig: true,
  }

  static rollbar = new Rollbar(Log.rollbarConfig)

  static error(message: string, data?: any) {
    try {
      if (!(isLocalhost || Log.dev)) {
        Log.rollbar.error(`${message}:${JSON.stringify(data)}`)
      }
      console.error(message, data ?? '')
    } catch (e) {
      console.error('Log.error failed')
    }
  }

  static warn(message: string, data?: any) {
    try {
      if (isLocalhost || Log.dev) {
        console.warn(message, data ?? '')
      } else {
        Log.rollbar.warn(`${message}:${JSON.stringify(data)}`)
      }
    } catch (e) {
      console.error('Log.warn failed')
    }
  }

  static info(message: string, data?: any) {
    try {
      if (isLocalhost || Log.dev) {
        console.info(message, data ?? '')
      }
    } catch (e) {
      console.error('Log.info failed')
    }
  }

  static debug(message: string, data?: any) {
    try {
      if (isLocalhost || Log.dev) {
        console.debug(message, data ?? '')
      }
    } catch (e) {
      console.error('Log.debug failed')
    }
  }

  static log(message: string, data?: any) {
    try {
      if (isLocalhost || Log.dev) {
        console.log(message, data ?? '')
      }
    } catch (e) {
      console.error('Log.log failed')
    }
  }
}

const global = window as any
global.xyLog = Log
