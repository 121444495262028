import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { FlexRow } from 'sdk-xy-react'

import MainPage from './Pages/Main'

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={MainPage} />
      </Switch>
    </BrowserRouter>
  )
}

const App: React.FC = () => {
  return (
    <FlexRow>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hot Tech Apps</title>
        <meta content="index, follow" name="robots" />
      </Helmet>
      <AppRouter />
    </FlexRow>
  )
}

export default App
